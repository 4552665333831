module.exports = new Promise(resolve => {
        const script = document.createElement("script")
        script.src = "https://federated-uitk-main-bucket.prod.epsdecaf.expedia.com/v1/remoteEntry.js"
        script.onload = () => {
          const proxy = {
            get: (request) => window.federatedUITK1.get(request),
            init: (arg) => {
              try {
                return window.federatedUITK1.init(arg)
              } catch (e) {
                console.log('Federated UITK v1 container already initialized')
              }
            }
          }
          resolve(proxy)
        }
        script.onerror = () => {
          console.log('Federated UITK v1 container could not be resolved')
          const proxy = {
            get: (request) => Promise.resolve(() => null),
            init: (arg) => null
          }
          resolve(proxy)
        }
        document.head.appendChild(script)
      });